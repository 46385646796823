/* eslint-disable react/jsx-key */
import React from "react"

//logo de empresas clientes
import EasyTrack from "@components/sharedComponents/customersLogos/logos/EasyTrack.svg"
import Savar from "@components/sharedComponents/customersLogos/logos/Savar.svg"
import Civa from "@components/sharedComponents/customersLogos/logos/Civa.svg"
import Ittsabus from "@components/sharedComponents/customersLogos/logos/IttsaBus.svg"
import Oltursa from "@components/sharedComponents/customersLogos/logos/Oltursa.svg"
import AmericaExpress from "@components/sharedComponents/customersLogos/logos/AmericaExpress.svg"

//imagen articulos blog
import article1 from "@components/sharedComponents/articleBlog/images/logistica-servicio-cliente.jpg"
import article2 from "@components/sharedComponents/articleBlog/images/como-mejorar-empresa-transporte-carga.jpg"
import article3 from "@components/sharedComponents/articleBlog/images/automatizar-pedidos-whatsapp.jpg"
import article4 from "@components/sharedComponents/articleBlog/images/intermediarios-comerciales.jpg"
import article5 from "@components/sharedComponents/articleBlog/images/automatizacion-whatsapp-respuestas.jpg"

// logos testimonios
import SavarTes from "@components/sharedComponents/testimonies/images/logos/savar.svg"

//foto testimonio desktop
import savarDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/savar.png"

//foto testimonio desktop
import savarMobile from "@components/sharedComponents/testimonies/images/fotosMobile/savar.png"

//image acordeon left
import ImageAccordionLeftOne from "@components/pageIndustrias/pageLogistica/images/imageAccordion/imageOne.webp"
import ImageAccordionLeftTwo from "@components/pageIndustrias/pageLogistica/images/imageAccordion/imageTwo.webp"
import ImageAccordionLeftThree from "@components/pageIndustrias/pageLogistica/images/imageAccordion/imageThree.webp"
import ImageAccordionLeftFour from "@components/pageIndustrias/pageLogistica/images/imageAccordion/imageFour.webp"

//iconos poteciamos operaciones
import imageConversionsOne from "@components/pageIndustrias/pageLogistica/images/imageConvertions/imageOne.webp"
import imageConversionsTwo from "@components/pageIndustrias/pageLogistica/images/imageConvertions/imageTwo.webp"
import imageConversionsThree from "@components/pageIndustrias/pageLogistica/images/imageConvertions/imageThree.webp"
import imageConversionsFour from "@components/pageIndustrias/pageLogistica/images/imageConvertions/imageFour.webp"

import imageVideo from "@components/sharedComponents/bannerVideo/image/imageLogistica.webp"

import TitleUnderlineStart from "@components/pageIndustrias/pageLogistica/titleUnderline/titleUnderlineStart"
import imageStartContactCenter from "@components/pageIndustrias/pageLogistica/images/imageStart.webp"


const titleStart = (
    <p>
        Automatiza la comunicación de los  <TitleUnderlineStart underline="procesos logísticos" /> con tecnología omnicanal impulsada por IA
    </p>
)

let data = {
    start: {
        title: titleStart,
        textbody: (
            <p>
                Desde la gestión de envíos hasta la atención al cliente, transforma cada punto de contacto en una experiencia positiva mediante autoservicio con Inteligencia Artificial, notificaciones automáticas, validación segura de datos y más.
                <br /><br />
                Nuestras soluciones están diseñadas para empresas de logística que buscan no solo adaptarse, sino liderar en la era digital, ofreciendo eficiencia operativa y satisfacción al cliente en cada etapa del proceso.
            </p>
        ),
        image: imageStartContactCenter,
        textbutton: "Habla con un experto",
        link: "/hablemos/",
    },
    customersDesktop: [
        EasyTrack,
        Savar,
        Civa,
        Ittsabus,
        Oltursa,
        AmericaExpress
    ],
    customersTablet: {
        arrayOne: [EasyTrack, Savar, Civa, Ittsabus, Oltursa, AmericaExpress],
    },
    accordionRight: [
        {
            title: "Notifica en cada fase de envío",
            description: "<p>Informa a los clientes en cada fase del envío (recojo, tránsito, distribución, entrega) mediante SMS, email y WhatsApp. Garantiza transparencia y mantén a los clientes siempre informados.</p>",
            image: ImageAccordionLeftOne
        },
        {
            title: "Envía mensajes de autenticación OTPs",
            description: "<p>Envía códigos de seguridad por SMS, email y WhatsApp para autenticar acciones críticas como confirmaciones de entrega y cambios de dirección. Mejora la seguridad y reduce el riesgo de fraude.</p>",
            image: ImageAccordionLeftTwo
        },
        {
            title: "Valida datos personales desde WhatsApp",
            description: "<p>Permite a los clientes validar datos personales directamente en WhatsApp. Utiliza chatbots con IA para guiar y confirmar cambios en tiempo real, agilizando los tiempos de entrega.</p>",
            image: ImageAccordionLeftThree,
        },
        {
            title: "Integra tus herramientas internas",
            description: "<p>Integra CRM, ERP y sistemas de gestión en un solo lugar. Automatiza flujos de trabajo, sincroniza datos en tiempo real y proporciona a los agentes una visión completa del cliente y la actualización de los envíos.</p>",
            image: ImageAccordionLeftFour
        },
    ],
    conversions: [
        {
            title: "Cambios en datos y pedidos",
            img: imageConversionsOne,
            text: "<p>Permite a los clientes modificar datos personales y pedidos de forma autónoma. Facilita la actualización inmediata sin necesidad de interacción humana.</p>"
        },
        {
            title: "Preguntas frecuentes",
            img: imageConversionsTwo,
            text: "<p>Responde preguntas frecuentes de manera automática. Proporciona respuestas instantáneas sobre horarios, políticas de envío y más, mejorando la eficiencia del servicio.</p>",
        },
        {
            title: "Reclamos y quejas",
            img: imageConversionsThree,
            text: "<p>Gestiona reclamos y quejas de forma automatizada. Recoge información detallada y dirige los casos a los agentes adecuados para una resolución rápida y eficiente.</p>",
        },
        {
            title: "Rastreo y Actualizaciones",
            img: imageConversionsFour,
            text: "<p>Ofrece rastreo de envíos y actualizaciones en tiempo real de manera automatizada. Mantén a los clientes informados sobre el estado de sus pedidos sin intervención humana.</p>",
        },
    ],

    articles: [
        {
            image: article1,
            title: "Servicio al cliente en el sector logística: 8 pasos para potenciarlo",
            textbody:
                "Conoce cómo mejorar el servicio al cliente en el sector logística. Esto te ayudará a aumentar los ingresos de tu negocio y a fidelizar.",
            link: "https://beexcc.com/blog/logistica-servicio-cliente/",
            card: true,
        },
        {
            image: article2,
            title: "Cómo mejorar una empresa de transporte de carga en 10 pasos",
            textbody:
                "Si buscas mejorar una empresa de transporte de carga, aquí te brindamos 10 estrategias nunca antes vistas para potenciarla.",
            link: "https://beexcc.com/blog/como-mejorar-empresa-transporte-carga/",
            card: true,
        },
        {
            image: article3,
            title: "Automatizar pedidos por WhatsApp: 6 ideas inspiradoras",
            textbody:
                "Conoce cuáles son las mejores técnicas de ventas por WhatsApp que debes saber para aumentar la rentabilidad de tu negocio.",
            link: "https://beexcc.com/blog/automatizar-pedidos-whatsapp/",
        },
        {
            image: article4,
            title: "¿Qué son los intermediarios comerciales? [Guía detallada]",
            textbody:
                "¿Qué son los intermediarios comerciales? Conoce qué papel desempeñan en las empresas y su importancia. ¡ENTRA AQUÍ y mejora tus ventas!",
            link: "https://beexcc.com/blog/intermediarios-comerciales/",
        },
        {
            image: article5,
            title: "Automatización de respuestas en WhatsApp: Guía para utilizar bots, botones y listas",
            textbody:
                "Descubre cómo automatizar respuestas en WhatsApp utilizando bots, botones y listas para mejorar la eficiencia y la experiencia del cliente.",
            link: "https://beexcc.com/blog/automatizacion-whatsapp-respuestas/",
        },
    ],
    contacts: {
        title: "No esperes más para brindar experiencias únicas a tus clientes",
        textbody: "Queremos ayudarte a que des el primer paso para mejorar tus interacciones. Agenda una reunión para que uno de nuestros especialistas se involucre con tus objetivos.",
        href: "/hablemos/",
        btn: "Quiero una asesoría gratuita",
    },
    footer: {
        product: "PRODUCTOS",
        productDesktopOne: "Conversations: Atención al cliente omnicanal y chatbots.",
        productMobileOne: "Conversations",
        linkone: "/conversations/",
        productDesktopTwo: " Contact Center: Software en la nube para Contact Center.",
        productMobileTwo: "Contact Center",
        linktwo: "/contact-center/",
        productDesktopFour: "Validation: Software de validación de teléfono.",
        productMobileFour: "Validation",
        linkfour: "/validation/",

        solution: "SOLUCIONES",
        solutionOne: "Atención al cliente",
        linkfive: "/atencion-al-cliente/",
        solutionTwo: "Telemarketing",
        linksix: "/marketing-ventas/",
        solutionThree: "Contact Center",
        linkseven: "/contact-center/",
        solutionFour: "Cobranzas",
        linkeight: "/software-cobranzas/",
        solutionFive: "WhatsApp",
        linkwhatsapp: "/whatsapp-business-api/",
        linkchatbots: "/chatbots-online/",
        solutionSix: "Chatbots",
        linkinstagram: "/chatbot-instagram/",
        solutionSeven: "Instagram",
        linkwebchat: "/chat-en-vivo-web/",
        solutionEight: "WebChat",

        company: "EMPRESA",
        companyOne: "Nosotros",
        linknine: "/nosotros/",
        companyTwo: "Casos de éxito",
        linkten: "/casos-de-exito/",
        companyThree: "Blog",
        linkeleven: "https://beexcc.com/blog/",
        companyFour: "Hablemos",
        linktwelve: "/hablemos/",
        companySix: "Documentación",
        linkdocs: "https://beexcc.com/docs/",
        companyFive: "Recursos",
        linktthirteen: "/recursos/",

        blog: "BLOG",
        blogone: "¿Cómo elegir el mejor software para call center?",
        linkblogone: "https://beexcc.com/blog/mejor-software-call-center/",

        blogtwo: "Plataforma Omnicanal: Qué es y cómo funciona",
        linkblogtwo: "https://beexcc.com/blog/plataforma-omnicanal/",

        blogthree: "¿Qué es WhatsApp Business API? - Guía completa 2023",
        linkblogthree: "https://beexcc.com/blog/que-es-whatsapp-business-api/",

        blogfour:
            "¿Cómo elegir el mejor chatbot? 5 características que debe cumplir",
        linkblogfour: "https://beexcc.com/blog/mejor-chatbot/",

        blogfive: "9 características que las herramientas de telemarketing",
        linkblogfive: "https://beexcc.com/blog/herramientas-telemarketing/",

        masblog: "Conoce más sobre nuestro blog",
        linkmasblog: "https://beexcc.com/blog/",

        address: "Jirón Pachacutec 1315, Of. 503, Jesús María, Lima, Perú",
        phone: "(+511) 277-4188",
        mail: "hi@beexcc.com",
        copyright: "Copyright© 2024 Beex Perú. Todos los derechos reservados.",
        privacyPolicy: "Política de Privacidad",
        privacyPolicyHref: "/politicas-privacidad",
        cookiesPolicy: "Política de Cookies",
        cookiesPolicyHref: "/politicas-cookies",
    },
    testimonies: [
        {
            image: SavarTes,
            title:
              "Con Beex, mejoramos +20% la satisfacción de nuestros clientes en entregas de pedidos",
            textbody:
              "Savar, empresa líder en logística, ha mejorado sus métricas más importantes de satisfacción notificando sobre el estado de sus envíos por WhatsApp.",
            name: "Dinkar Rios Macedo",
            post: "Jefe de Sistemas",
            link: "/casos-de-exito/savar/",
            imgDesktop: savarDesktop,
            imgMobile: savarMobile,
            metrics: [
              {
                metric: "+20%",
                descripcion: "aumentó la satisfacción de sus clientes",
                color: "#FFB800",
              },
              {
                metric: "+30%",
                descripcion: "se redujeron las consultas repetitivas",
                color: "#5831D8",
              },
              {
                metric: "25%",
                descripcion: "mejoró el tiempo de resolución de casos",
                color: "#00CA56",
              },
            ],
          },
    ],
    bannerVideo: {
        title: "Logística omnicanal: Qué hacer para aplicar este proceso con éxito",
        description: "<p> Descubre estrategias que pueden transformar tu gestión de leads en una concesionaria. Analiza cómo optimizar procesos, desde la captación hasta el cierre de ventas. Explora técnicas que mejorarán la experiencia del cliente y aumentarán la efectividad de tu equipo.<br/><br/>Aprende métodos para mantener el interés y la lealtad de los potenciales compradores.</p>",
        image: imageVideo
    },

}

export default data
